.addlectuer .addlist h3 {
  display: flex;
  border-left: 5px solid #5c6be8;
  padding: 0;
  margin-bottom: 30px;
}
.addlectuer .addlist h3 span {
  margin-left: 20px;
}
.addlectuer .addlist h3::before {
  content: "";
  border: 0;
}
.zsBtn {
  margin-left: 20px;
}
.zsBtn button {
  height: 40px;
}
.form-box {
  padding: 10px 20px;
}
.form-box .form {
  width: 70%;
  padding: 20px;
}
.form-box .form-bg {
  background-color: #f7f8fd;
}
.btn-box {
  padding: 20px 20%;
  display: flex;
  justify-content: space-around;
  width: 70%;
}
.btn-box button {
  padding: 12px 30px;
}
.table-switch .el-switch__core {
  width: 40px !important;
}
.table-switch .el-switch__core:after {
  background-color: #fff;
}
.table-switch span {
  line-height: 20px;
  margin-left: 5px;
}
